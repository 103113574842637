import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { ProjectCard } from "./ProjectCard";
import colorSharp2 from "../assets/img/color-sharp2.png";
import proImg1 from "../assets/img/PortfolioHome.png";
import proImg2 from "../assets/img/AdminHome.png";
import proImg3 from "../assets/img/GameStoreHome.png";
import proImg4 from "../assets/img/E.R.R.S.E.L.AImage.png";
import proImg5 from "../assets/img/Webstore.png";
import proImg6 from "../assets/img/KNNImage.png";
import proImg7 from "../assets/img/VTravelImage.png";
import proImg8 from "../assets/img/PerceptronImage.png";
import proImg9 from "../assets/img/FightingGame.png";
import 'animate.css';
import TrackVisibility from "react-on-screen";


export const Projects = () => {

    const projects = [
        {
        title: "Personal Porfolio",
        description: "Design & Development",
        imgUrl: proImg1,
        },
        {
        title: "Admin Dashboard",
        description: "Design & Development",
        imgUrl: proImg2,
        },
        {
        title: "Game Store",
        description: "Design & Development",
        imgUrl: proImg3,
        },
    ];

    const projects2 = [
      {
        title: "E.R.R.S.E.L.A",
        description: "Design and Development",
        imgUrl: proImg4,
      },
      {
        title: "Webstore",
        description: "Design & Development",
        imgUrl: proImg5,
      },
      {
        title: "K-Nearest Neighbor",
        description: "Algorithm",
        imgUrl: proImg6,
      },
    ];

    const projects3 = [
      {
        title: "VTravel",
        description: "Design and Development",
        imgUrl: proImg7,
      },
      {
        title: "Perceptron",
        description: "Algorithm",
        imgUrl: proImg8,
      },
      {
        title: "Arcade Fighting Game",
        description: "Game Development",
        imgUrl: proImg9,
      },
    ];



return (
     <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>A current list of projects that I have worked on either as student or as passion projects.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Tab 1</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Tab 2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Tab 3</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__bounce" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Row>
                      {
                          projects2.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <Row>
                      {
                          projects3.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
)
}

export default Projects;