import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/linked-in.svg";
import navIcon2 from "../assets/img/google.svg";
import navIcon3 from "../assets/img/github.svg";


export const NavBar = () => {
    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, setScrolled] = useState (false);

    //Manages what happens when a user scrolls past a certain point with event listener
    useEffect (() => {
        const onScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        }

        window.addEventListener("scroll", onScroll);
        //removes event listener when component gets removed from the DOM
        return () => window.removeEventListener("scroll", onScroll);
    }, [])

    const onUpdateActiveLink = (value) => {
        setActiveLink(value);
    }

    return (
        <Navbar expand="lg" className={scrolled ? "scrolled": ""}>
        <Container>
          <Navbar.Brand href="#home">
            <img src={logo} alt-="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
              <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link>
              <Nav.Link href="#projects"className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}>Projects</Nav.Link>
            </Nav>
            <span className="navbar-text">
                <div className="social-icon">
                    <a href="https://www.linkedin.com/in/michael-gordon-0a4868259/"><img src={navIcon1} alt="linked-in" /></a>
                    <a href="mailto:MichaelSGordon18@gmail.com"><img src={navIcon2} alt="gmail" /></a>
                    <a href="https://github.com/Mixitus"><img src={navIcon3} alt="github" /></a>
                </div>
                <button className="vvd"> <a href="mailto:MichaelSGordon18@gmail.com"><span></span>Let's connect</a></button>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
};

export default NavBar;