import {Container} from "react-bootstrap";
import MailchimpSubscribe, { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/linked-in.svg";
import navIcon2 from "../assets/img/google.svg";
import navIcon3 from "../assets/img/github.svg";
import  Row  from "react-bootstrap/Row";
import  Col  from "react-bootstrap/Col";


export const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Row className="align-items-center">
                <MailchimpForm />
                <Col sm={6}>
                <img src={logo} alt="Logo"/>
                </Col>
                <Col sm={6} className="text-center text-sm-end">
                <div className="social-icon">
                    <a href="https://www.linkedin.com/in/michael-gordon-0a4868259/"><img src={navIcon1} /></a>
                    <a href="mailto:MichaelSGordon18@gmail.com"><img src={navIcon2} /></a>
                    <a href="https://github.com/Mixitus"><img src={navIcon3} /></a>
                </div>
                <p><b>CopyRight 2024. All Right Reserved. Michael Gordon</b></p>
                <p><b>MichaelSGordon18@gmail.com</b></p>
                </Col>
                </Row>
            </Container>
        </footer>
    )
}