import {useState, useEffect } from 'react';
import {Container, Row, Col } from "react-bootstrap";
import { ArrowRightCircle } from "react-bootstrap-icons";
import profileImg from "../assets/img/Michael.jpg";
import headerImg from '../assets/img/Test.svg';
import headerImg2 from '../assets/img/phoenix.png';
import 'animate.css';
import TrackVisibility from 'react-on-screen';



export const Banner = () => {
    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const toRotate = ["Web Developer", "Web Designer", "UI/UX Designer" ];
    const [text, setText] = useState('');
    const [delta, setDelta] = useState(300 - Math.random() * 100);
    const period = 2000;

    useEffect(() => {
        let ticker = setInterval(() => {
            tick();
        }, delta)

        return () => { clearInterval(ticker)};
    }, [text])

    const tick = () => {
        let i = loopNum % toRotate.length;
        let fullText = toRotate[i];
        let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

        setText(updatedText);

        if(isDeleting) {
            setDelta(prevDelta => prevDelta /2)
        }

        if(!isDeleting && updatedText === fullText) {
            setIsDeleting(true);
            setDelta(period);
        }else if (isDeleting && updatedText === '') {
            setIsDeleting(false);
            setLoopNum(loopNum + 1);
            setDelta(500);
        }
    }


    return (
        <section className="banner" id="home">
        <Container>
            <Row className="align-items-center">
                <Col xs={12} md={6} xl={7} >
                <div className="profile-image"> 
                <img src={profileImg} alt="Profile Img" />
                </div>
                <TrackVisibility>
                {({ isVisible }) => 
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <span className="tagline">Welcome to my Portfolio</span>
                <h1>{`Hi I'm Michael Gordon `} <span className="wrap">{text}</span></h1>
                <p>A Front-End Engineer familiar with React, Html, CSS, and Javascript. An alumni from the New York Institute of Technology where I studied Software Engineering and Web Development.</p>
                    <button> <a href="mailto:MichaelSGordon18@gmail.com"><span></span>Let's connect</a><ArrowRightCircle size={25} /></button>
                </div>}
                </TrackVisibility>
                </Col>
                <Col xs={12} md={6} xl={5}>
                <img src={headerImg} alt="Header Img" z-index="2" />
                </Col>
            </Row>
        </Container>
        </section>
    )
}

export default Banner;